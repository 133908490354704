import * as actionTypes from './actionTypes';

export const openExportElementModal = (currentElement) => ({ type: actionTypes.OPEN_EXPORT_ELEMENT_MODAL, currentElement });
export const closeExportElementModal = () => ({ type: actionTypes.CLOSE_EXPORT_ELEMENT_MODAL });
export const toggleExportElementModal = () => ({ type: actionTypes.TOGGLE_EXPORT_ELEMENT_MODAL });
export const cleanExportElementModal = () => ({ type: actionTypes.CLEAN_EXPORT_ELEMENT_MODAL });

export const initAvailableExportDefinitions = (exportDefinitions) => ({ type: actionTypes.INIT_AVAILABLE_EXPORT_DEFINITIONS, exportDefinitions });
export const fetchAvailableExportDefinitions = () => ({ type: actionTypes.FETCH_AVAILABLE_EXPORT_DEFINITIONS });
export const addNewExportJob = (currentElement, elementMetaType, exportDefinition) => ({ type: actionTypes.ADD_NEW_EXPORT_JOB, currentElement, elementMetaType, exportDefinition });
export const startAddingNewExportJob = () => ({ type: actionTypes.START_ADDING_EXPORT_ELEMENT });
export const finishAddingNewExportJob = () => ({ type: actionTypes.FINISH_ADDING_EXPORT_ELEMENT });
