import { combineReducers } from 'redux';
import * as actionTypes from 'api/dataManagement/actionTypes';
import defaultStore from 'store/defaultStore';

export const exportDataReducer = (state = defaultStore.dataManagement.exportData, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_ALL_EXPORTS:
            return { ...state, isFetchingExports: true };
        case actionTypes.FINISH_FETCHING_ALL_EXPORTS:
            return { ...state, isFetchingExports: false };
        case actionTypes.INIT_ALL_EXPORTS:
            return { ...state, data: action.data };
        default:
            return state;
    }
};

export const dataManagementReducer = combineReducers({
    exportData: exportDataReducer
});
