export const OPEN_EXPORT_ELEMENT_MODAL = 'OPEN_EXPORT_ELEMENT_MODAL';
export const CLOSE_EXPORT_ELEMENT_MODAL = 'CLOSE_EXPORT_ELEMENT_MODAL';
export const TOGGLE_EXPORT_ELEMENT_MODAL = 'TOGGLE_EXPORT_ELEMENT_MODAL';
export const CLEAN_EXPORT_ELEMENT_MODAL = 'CLEAN_EXPORT_ELEMENT_MODAL';

export const FETCH_AVAILABLE_EXPORT_DEFINITIONS = 'FETCH_AVAILABLE_EXPORT_DEFINITIONS';
export const START_FETCHING_AVAILABLE_EXPORT_DEFINITIONS = 'START_FETCHING_AVAILABLE_EXPORT_DEFINITIONS';
export const INIT_AVAILABLE_EXPORT_DEFINITIONS = 'INIT_AVAILABLE_EXPORT_DEFINITIONS';
export const FINISH_FETCHING_AVAILABLE_EXPORT_DEFINITIONS = 'FINISH_FETCHING_AVAILABLE_EXPORT_DEFINITIONS';
export const ADD_NEW_EXPORT_JOB = 'ADD_NEW_EXPORT_JOB';
export const START_ADDING_EXPORT_ELEMENT = 'START_ADDING_EXPORT_ELEMENT';
export const FINISH_ADDING_EXPORT_ELEMENT = 'FINISH_ADDING_EXPORT_ELEMENT';
