import defaultStore from 'store/defaultStore';
import * as actionTypes from 'api/catalog/exportElement/actionTypes';

export const exportElementReducer = (state = defaultStore.catalog.exportElement, action) => {
    switch (action.type) {
        case actionTypes.START_ADDING_EXPORT_ELEMENT:
            return { ...state, isAddingExport: true };
        case actionTypes.FINISH_ADDING_EXPORT_ELEMENT:
            return { ...state, isAddingExport: false };
        case actionTypes.INIT_AVAILABLE_EXPORT_DEFINITIONS:
            return { ...state, availableExportDefinitions: action.exportDefinitions };
        case actionTypes.OPEN_EXPORT_ELEMENT_MODAL:
            return { ...state, isModalOpen: true, currentElement: { ...action.currentElement } };
        case actionTypes.CLOSE_EXPORT_ELEMENT_MODAL:
            return { ...state, isModalOpen: false, currentElement: null };
        case actionTypes.TOGGLE_EXPORT_ELEMENT_MODAL:
            return {
                ...state,
                isModalOpen: !state.isModalOpen,
                currentElement: state.isModalOpen ? null : state.currentElement
            };
        case actionTypes.CLEAN_EXPORT_ELEMENT_MODAL:
            return {
                ...state,
                currentElement: null
            };
        default:
            return state;
    }
};
