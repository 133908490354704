import { put } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import * as actions from 'api/dataManagement/actions';
import * as actionTypes from 'api/dataManagement/actionTypes';
import {
    getExportsApi
} from 'api/dataManagement/remoteApi';

function* fetchAllExportsFromApi() {
    const data = yield apiCall(getExportsApi);
    yield put(actions.initAllExports(data));
}

function* fetchAllExports(action) {
    yield put(actions.startFetchingAllExports());
    yield guardedGenerator(fetchAllExportsFromApi, action);
    yield put(actions.finishFetchingAllExports());
}

export function* watchFetchAllExports() {
    yield guardedTakeEvery(actionTypes.FETCH_ALL_EXPORTS, fetchAllExports);
}
